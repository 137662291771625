import React from 'react';
import { Form, Button } from 'react-bootstrap';

function Contact(props) {
    return (
        <div className='d-flex-column ' id={props.id}>
            <h1>I Am The Contact</h1>
        </div>
    );
}

export default Contact;
