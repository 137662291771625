import React from 'react';

function Footer(props) {
    return (
        <div id={props.id}>
            <h1>I Am The Footer</h1>
        </div>
    );
}

export default Footer;
